<template>
    <div class="pricing">
        <div class="info-free">
            <div class="container">
                <div class="pricing-info">
                    <div class="intro">
                        <h1>{{ $t('page.pricing.community.title') }}</h1>
                        <p>{{ $t('page.pricing.community.intro') }}</p>
                    </div>
                    <div class="spacing"></div>
                    <div class="price">
                        <div class="cost">
                            <h2>{{ $t('page.pricing.community.price') }}</h2>
                        </div>
                        <router-link to="/download" class="big-btn">{{ $t('page.pricing.community.download') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-pro">
            <div class="container">
                <div class="pricing-info">
                    <div class="intro">
                        <h1>{{ $t('page.pricing.pro.title') }}</h1>
                        <p>{{ $t('page.pricing.pro.intro') }}</p>
                    </div>
                    <div class="spacing"></div>
                    <div class="price">
                        <div class="cost">
                            <span>{{ $t('page.pricing.pro.price_description') }}</span>
                            <h2>{{ $t('page.pricing.pro.price') }}</h2>
                        </div>
                        <router-link to="/buy" class="big-btn">{{ $t('page.pricing.pro.buy') }}</router-link>
                    </div>
                </div>
                <div class="four-row">
                    <div class="item">
                        <h2>{{ $t('page.pricing.pro.feature.support_title') }}</h2>
                        <p>{{ $t('page.pricing.pro.feature.support_description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.pricing.pro.feature.command_line_title') }}</h2>
                        <p>{{ $t('page.pricing.pro.feature.command_line_description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.pricing.pro.feature.ske_ani_title') }}</h2>
                        <p>{{ $t('page.pricing.pro.feature.ske_ani_description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.pricing.pro.feature.pro_tool_title') }}</h2>
                        <p>{{ $t('page.pricing.pro.feature.pro_tool_description') }}</p>
                    </div>
                </div>
                <business-contact></business-contact>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessContact from '../components/common/BusinessContact.vue'

export default {
    name: 'Pricing',
    components: {
        BusinessContact,
    }
}
</script>

<style lang="scss">
.pricing-info {
    display: flex;
    align-items: center;

    .intro {
        width: 50%;
        padding-right: 50px;

        h1 {
            font-size: 48px;
            color: #909090;
            margin: 0;
            margin-bottom: 16px;
            line-height: 1;
        }
        p {
            margin: 0;
            font-size: 24px;
        }
    }
    .spacing {
        width: 1%;
        flex-grow: 1;
    }
    .price {
        width: 300px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        background: rgba(#000, .05);
        padding: 15px 20px;

        .cost {
            h2 {
                margin: 0;
                font-size: 36px;
                line-height: 1;
                margin-right: 20px;
            }
            span {
                font-size: 12px;
            }
        }
        .big-btn {
            border-radius: 6px;
            padding: 10px 15px;
            width: 1%;
            flex-grow: 1;
            text-align: center;
        }
    }
    @media (max-width: 576px) {
        flex-direction: column;

        .intro {
            width: 100%;
            padding-right: 0;
            margin-bottom: 25px;

            p {
                font-size: 18px;
            }
        }
        .price {
            width: 100%;
        }
    }
}

.info-free {
    padding-top: 80px;
    padding-bottom: 66px;
}

.info-pro {
    background: #000;
    color: #fff;

    .container {
        padding-top: 66px;
        background: url(../assets/decoration/pop-black.svg) no-repeat;
        background-size: 100% auto;
        background-position: center top -50px;
    }
    .pricing-info {
        .intro {
            h1 {
                color: #fff;
            }
            p {
                color: #9E9E9E;
            }
        }
        .price {
            background: rgba(#fff, .14);
            backdrop-filter: blur(10px);
        }
    }
    .four-row {
        margin-top: 68px;
        margin-bottom: 68px;

        .item {
            h2 {
                font-size: 24px;
                margin: 0;
                line-height: 1.15;
            }
            p {
                color: #BEBEBE;
                font-size: 14px;
                line-height: 1.75;
            }
        }
    }
}

.business-contact {
    border-top: 4px solid rgba(#fff, .2);
}
</style>